<template>
<div class="main">
  <Tree service="/sys/sysOrganization/treeQueryAuthCommon/org" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  this.formOptions.data["parentId"] = this.currNode.id;
                  this.$refs.xGrid.insertEvent();
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "小区名称",
            minWidth: 200,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入小区名称",
              },
            },
          },
          {
            field: "address",
            title: "小区地址",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return cellValue
              }
            },
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入小区地址",
              },
            },
          },
          {
            field: "communityArea",
            title: "小区面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                max: 99999,
                placeholder: "请输入小区面积",
              },
            },
          },
          {
            field: "communityUseArea",
            title: "使用面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区使用面积（米²）",
              },
            },
          },
          {
            field: "residentialArea",
            title: "住宅面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,
                maxlength: 9,
                placeholder: "请输入住宅面积（米²）",
              },
            },
          },
          {
            field: "commercialArea",
            title: "商业面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入商业面积（米²）",
              },
            },
          },
          {
            field: "communityBuiltUpArea",
            title: "建筑面积",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区建筑面积（米²）",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent",
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editRules: {
          name: [
            { required: true, message: "请输入小区名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          // address: [{ required: true, message: "请输入小区地址" }],
          // communityArea: [{ required: true, message: "请输入小区面积" }],
          parentId: [{ required: true, message: "请选择所属公司" }],
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [
          // {
          //     field: "map",
          //     title: "地址",
          //     span: 24,
          //     itemRender: {
          //         name: "$VxeSelectMap",
          //         props: {
          //             placeholder: "请选择地址",
          //         },
          //         events: {
          //             change: (data) => {
          //             }
          //         }
          //     },
          // },
          {
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            },
          },
          {
            field: "name",
            title: "小区名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入小区名称",
              },
            },
          },
          {
            field: "communityArea",
            title: "小区面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区面积",
              },
            },
          },
          {
            field: "address",
            title: "小区地址",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入小区地址",
              },
            },
          },
          // {
          //   field: "nearbyLandmarks",
          //   title: "地标",
          //   span: 24,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       maxlength: 128,
          //       placeholder: "请输入地标（如王府井北60米）",
          //     },
          //   },
          // },
          {
            field: "communityUseArea",
            title: "使用面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区使用面积（米²）",
              },
            },
          },
          {
            field: "residentialArea",
            title: "住宅面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入住宅面积（米²）",
              },
            },
          },
          {
            field: "commercialArea",
            title: "商业面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入商业面积（米²）",
              },
            },
          },
          {
            field: "communityBuiltUpArea",
            title: "建筑面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区建筑面积（米²）",
              },
            },
          },
          {
            field: "sharedArea",
            title: "公摊面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,

                maxlength: 9,
                placeholder: "请输入小区公摊面积（米²）",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: "seal",
            title: "公章",
            span: 12,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["seal"] = data;
                }
              }
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          name: [
            { required: true, message: "请输入小区名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          // address: [{ required: true, message: "请输入小区地址" }],
          // communityArea: [{ required: true, message: "请输入小区面积" }],
          parentId: [{ required: true, message: "请选择所属公司" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          type: "1001",
          enabled: "1",
          seal: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "小区名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入小区名称",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                maxlength: 24,
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          enabled: "",
        },
      },
    };
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
